import { defineMessages } from 'react-intl-next';

export const SuperAdminSatisfactionSurveyI18n = defineMessages({
	superAdminSatisfactionSurveyTitle: {
		id: 'super-admin.satisfaction.survey.title',
		defaultMessage: 'How was your admin key experience?',
		description: 'Title for Admin Key Satisfaction Survey, shown after exiting Admin Key mode',
	},
	superAdminSatisfactionSurveyFinalTitle: {
		id: 'super-admin.satisfaction.survey.final.title',
		defaultMessage: 'Thank you',
		description: 'Final title for Admin Key Satisfaction Survey after submission',
	},
	superAdminSatisfactionSurveyFinalDescription: {
		id: 'super-admin.satisfaction.survey.final.description',
		defaultMessage: 'Your feedback has been submitted',
		description: 'Final Description for Admin Key Satisfaction Survey after submission',
	},
	superAdminSatisfactionSurveyReactionTerrible: {
		id: 'super-admin.satisfaction.survey.reaction.terrible',
		defaultMessage: 'Terrible',
		description: 'Reaction icon tooltip for terrible',
	},
	superAdminSatisfactionSurveyReactionBad: {
		id: 'super-admin.satisfaction.survey.reaction.bad',
		defaultMessage: 'Bad',
		description: 'Reaction icon tooltip for bad',
	},
	superAdminSatisfactionSurveyReactionOkay: {
		id: 'super-admin.satisfaction.survey.reaction.okay',
		defaultMessage: 'Okay',
		description: 'Reaction icon tooltip for okay',
	},
	superAdminSatisfactionSurveyReactionGreat: {
		id: 'super-admin.satisfaction.survey.reaction.great',
		defaultMessage: 'Great',
		description: 'Reaction icon tooltip for great',
	},
	superAdminSatisfactionSurveyReactionAmazing: {
		id: 'super-admin.satisfaction.survey.reaction.amazing',
		defaultMessage: 'Amazing',
		description: 'Reaction icon tooltip for amazing',
	},
	superAdminSatisfactionSurveyQuestionBad: {
		id: 'super-admin.satisfaction.survey.question.bad',
		defaultMessage: 'We’re sorry to hear that. What went wrong? (optional)',
		description: 'Prompt for additional feedback when the user is unhappy',
	},
	superAdminSatisfactionSurveyQuestionGood: {
		id: 'super-admin.satisfaction.survey.question.good',
		defaultMessage: 'Thanks! What did you like about it? What could be improved? (optional)',
		description: 'Prompt for additional feedback when the user is happy',
	},
	superAdminSatisfactionSurveyButtonSubmit: {
		id: 'super-admin.satisfaction.survey.button.submit',
		defaultMessage: 'Submit',
		description: 'Button to submit the survey',
	},
	superAdminSatisfactionSurveyButtonClose: {
		id: 'super-admin.satisfaction.survey.button.close',
		defaultMessage: 'Close',
		description: 'Button to close the survey without submitting',
	},
});
